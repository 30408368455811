.icheckbox_line-orange, .iradio_line-orange {
    padding: 9px 15px 8px 38px;
}

.icheckbox_line-orange, .iradio_line-orange {
    opacity: 0.6;
}

.icheckbox_line-orange.checked, .iradio_line-orange.checked {
    opacity: 1;
}

.icheckbox_line-green, .iradio_line-green {
    background: #bbb;
    display: inline-block;
    padding: 10px 15px 10px 38px;
    width: 100%;
}

.icheckbox_line-green.checked, .iradio_line-green.checked {
    background: #5CB85C;
}