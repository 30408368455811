.box-upload {
    background: #d3dce6;
    border: 1px solid #a8c4e3;
    border-radius: 2px;
    color: #001F3F;
    display: inherit;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    vertical-align: middle;
    width: 100%;
}

.box-upload .inputfile {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
}

.box-upload:hover {
    color: #3C8DBC;
}

.box-upload label {
    cursor: pointer;
    padding: 35px 20px 50px;
}

.box-upload label i:before {
    display: inline-block;
    font-size: 60px;
    width: 100%;
}