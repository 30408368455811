.square-gallery {
    width: 100%;
}

.square-gallery .thumb {
    margin: 0 0 10px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.square-gallery .thumb > div {
    background: rgba(0, 0, 0, 0.7);
    padding: calc(40%) 0;
    position: absolute;
    right: 0;
    top: -150%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 100%;
    width: 100%;
    text-align: center;
    border-radius: 4px;
}

.square-gallery .thumb:hover > div {
    top: 0;
    right: 0;
}

.square-gallery .thumb i {
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    margin: 0 8px;
}

.square-gallery .thumb i:hover {
    color: #ffc42a;
}

.square-gallery .thumb img {
    height: 140px;
    object-fit: cover;
    width: 100%;
}

.square-gallery.magazine .thumb img {
    height: 180px;
}

.square-gallery .handle {
    position: absolute;
    top: 0;
    width: 100%;
    background: #ffa50080;
    left: 0;
    padding: 7px 0 5px;
    cursor: move;
}

.square-gallery .handle i {
    font-size: 19px;
    cursor: move;
}

@media (max-width: 500px) {
    .square-gallery .thumb {
        width: initial;
    }
}

/*====================================*/
.square-main-image {
    background: #000;
    border-radius: 4px;
    height: 52px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.square-main-image > img {
    height: 52px;
    width: 100%;
    object-fit: cover;
}

.square-main-image:hover > img {
    opacity: 0.3;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

.square-main-image > div {
    border-radius: 4px;
    height: 100%;
    padding: 6px 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    width: 100%;
}

.square-main-image .photoswipe {
    display: inline-block;
}

.square-main-image > div {
    top: -100%;
}

.square-main-image:hover > div {
    top: 0;
}

.square-main-image > div > a, .square-main-image .photoswipe a {
    border: 2px solid #fff;
    color: #fff;
    font-size: 16px;
    margin: 0 5px;
}

.square-main-image > div > a:hover, .square-main-image .photoswipe a:hover {
    border-color: #F39C12;
    color: #F39C12;
}

.square-main-image.square-avatar, .square-main-image.square-avatar > img {
    height:  auto;
}

.square-main-image.square-avatar:hover > div {
    top: calc(50% - 30px);
}
/*====================================*/