#period-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: right;
	margin-bottom: 15px;
	border-bottom: 3px solid #ddd;
	padding-bottom: 15px;
}

#period-container form {
	display: flex;
	flex-direction: row;
}

#period-container .input-container {
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	border: 1px solid #ccc;
	padding: 2px 5px 0;
	background: #dae0e8;
	border-radius: 4px;
	letter-spacing: .5px;
	box-shadow: 0 9px 14px -12px rgba(0, 0, 0, 0.4);
}

#period-container label {
	margin: 0;
	font-size: 12px;
	font-weight: normal;
}

#period-container input {
	border: none;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: .5px;
	background: #dae0e8;
}

#period-container button {
	border-radius: 4px;
	border: none;
	background: #09904d;
	min-width: 100px;
	font-weight: bold;
	text-transform: uppercase;
	color: #fff;
	box-shadow: 0 9px 14px -12px rgba(0, 0, 0, 0.4);
}

#period-container button:hover {
	background: #0ba65a;
}

#report .report-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 15px;
}

#report .report-card {
	margin-bottom: 20px;
	border-bottom: 10px solid;
	transition: opacity .3s;
	position: relative;
	width: calc(50% - 10px);
}

#report .report-card a {
	display: inline-block;
	width: 100%;
	padding: 20px 40px;
}

#report .report-card:hover {
	opacity: .8;
}

#report .report-card h3 {
	color: #fff;
	font-size: 40px;
	font-family: 'Roboto', sans-serif;
	margin: 0 0 10px;
}

#report .report-card p {
	margin-bottom: 0;
	color: #fff;
	font-size: 18px;
	letter-spacing: .4px;
}

#report .report-card.card-info-in {
	background-color: #08c0ef;
	border-color: #06a7cf;
}

#report .report-card.card-info-out {
	background-color: #06a7cf;
	border-color: #08c0ef;
}

#report .report-card.card-success-in {
	background-color: #0ba65a;
	border-color: #09904d;
}

#report .report-card.card-success-out {
	background-color: #09904d;
	border-color: #0ba65a;
}

#report .report-card.card-danger-in {
	background-color: #dd4b39;
	border-color: #c04131;
}

#report .report-card.card-danger-out {
	background-color: #c04131;
	border-color: #dd4b39;
}

#report .report-card .overlay {
	z-index: 1;
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	padding-bottom: 10px;
	display: flex;
	color: #fff;
	justify-content: center;
	align-items: center;
	font-size: 35px;
}

#report .report-box-table .table {
	margin-top: 0 !important;
	border-spacing: 1px;
}

#report .report-box-table thead {
	background: #08c0ef;
}

#report .report-box-table thead th {
	border-bottom: none;
	padding-top: 13px;
	padding-bottom: 13px;
	color: #fff;
	font-weight: 200;
	font-size: 18px;
	padding-left: 15px;
}

#report .report-box-table th.sorting_asc, #report .report-box-table th.sorting_desc {
	color: #000;
}

#report .report-box-table tbody {
	background: #e2e6ec;
}

#report .report-box-table tbody tr {
	color: #2e2e2e;
	font-size: 16px;
	letter-spacing: .5px;
}

#report .report-box-table tbody a {
	color: #2e2e2e;
	display: inline-block;
	width: 100%;
}

#report .report-box-table tbody a:hover {
	color: #08c0ef;
}

#report #DataTables_Table_0_paginate {
	display: none;
}

#report + #modal .modal-dialog {
	width: calc(100% - 20px);
	max-width: 700px;
}

#report + #modal .modal-content {
	max-height: calc(100vh - 60px);
	overflow-y: scroll;
}

@media (min-width: 1450px) {
	#report .col-lgg-6 {
		width: 50%;
		float: left;
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
	}
}

@media (max-width: 767px) {
	#period-container {
		justify-content: center;
		padding: 0;
	}

	#period-container form {
		flex-direction: column;
		width: 100%;
	}

	#period-container .input-container {
		margin: 0 0 20px;
	}

	#period-container button {
		height: 46px;
	}

	#report .report-card {
		width: 100%;
	}
}