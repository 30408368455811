@font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/flaticon.eot");
    src: url("../fonts/flaticon/flaticon.eot#iefix") format("embedded-opentype"), url("../fonts/flaticon/flaticon.woff") format("woff"), url("../fonts/flaticon/flaticon.ttf") format("truetype"), url("../fonts/flaticon/flaticon.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/lines/flatLines.eot");
    src: url("../fonts/flaticon/lines/flatLines.eot#iefix") format("embedded-opentype"), url("../fonts/flaticon/lines/flatLines.woff") format("woff"), url("../fonts/flaticon/lines/flatLines.ttf") format("truetype"), url("../fonts/flaticon/lines/flatLines.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-archive44:before {
    content: "\e000";
}

.flaticon-boxes2:before {
    content: "\e001";
}

.flaticon-businessman99:before {
    content: "\e002";
}

.flaticon-calendar69:before {
    content: "\e003";
}

.flaticon-checkmark8:before {
    content: "\e004";
}

.flaticon-clipboard109:before {
    content: "\e005";
}

.flaticon-coins21:before {
    content: "\e006";
}

.flaticon-credit33:before {
    content: "\e007";
}

.flaticon-delivery50:before {
    content: "\e008";
}

.flaticon-dollar9:before {
    content: "\e009";
}

.flaticon-finance-and-business4:before {
    content: "\e00a";
}

.flaticon-logout13:before {
    content: "\e00b";
}

.flaticon-male226:before {
    content: "\e00c";
}

.flaticon-manager3:before {
    content: "\e00d";
}

.flaticon-money392:before {
    content: "\e00e";
}

.flaticon-news1:before {
    content: "\e00f";
}

.flaticon-office-worker1:before {
    content: "\e010";
}

.flaticon-progress:before {
    content: "\e011";
}

.flaticon-responsive12:before {
    content: "\e012";
}

.flaticon-sell2:before {
    content: "\e013";
}

.flaticon-seo40:before {
    content: "\e014";
}

.flaticon-shopping160:before {
    content: "\e015";
}

.flaticon-spanner2:before {
    content: "\e016";
}

.flaticon-squares36:before {
    content: "\e017";
}

.flaticon-users6:before {
    content: "\e018";
}

.flaticon-wrenches5:before {
    content: "\e019";
}

.flaticon-lines:before {
    content: "\f100";
}