.tagEditor + .tag-editor {
    border-color: #d2d6de;
    min-height: 34px;
}

.tagEditor + .tag-editor .tag-editor-tag {
    background: #555;
    border-radius: 2px 0 0 2px;
    color: #ccc;
}

.tagEditor + .tag-editor .tag-editor-spacer {
    width: 7px;
}

.tagEditor + .tag-editor .tag-editor-delete {
    background: #555;
}

.tagEditor + .tag-editor.active {
    color: #555;
}