.flat-spin {
    animation-duration: 2500ms;
    -moz-animation-duration: 2500ms;
    -webkit-animation-duration: 2500ms;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-name: spin;
    -moz-animation-name: spin;
    -webkit-animation-name: spin;

    animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    display: inline-block;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}