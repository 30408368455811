/*
  Flaticon icon font: Flaticon
  Creation date: 17/04/2017 18:31
  */

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/square-flaticon/Flaticon.eot");
    src: url("../fonts/square-flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/square-flaticon/Flaticon.woff") format("woff"),
    url("../fonts/square-flaticon/Flaticon.ttf") format("truetype"),
    url("../fonts/square-flaticon/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../fonts/square-flaticon/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="square-flaticon"]:before, [class*=" square-flaticon"]:before,
[class^="square-flaticon"]:after, [class*=" square-flaticon"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
}

.flaticon-transport:before {
    content: "\f100";
}